{
  "common": {
    "code": "Code",
    "title": "Title",
    "description": "Description",
    "yes": "Yes",
    "no": "No",
    "ok": "Ok",
    "back": "Back",
    "percent": "%",
    "comment": "Comment",
    "date": "Date",
    "expiration": "Expiration",
    "data": "Data",
    "status": "Status | Statuses",
    "information": "Information",
    "see": "See",
    "colors": "Color | Colors",
    "total": "Total",
    "erp": "ERP",
    "b2b": "B2B",
    "and": "and",
    "form": {
      "placeHolder": {
        "date": "Select a date",
        "comment": "Write your comment here (max {count} characters)",
        "email": "Write here the emails separated by commas",
        "orderName": "Write here the name of the order"
      },
      "noDataText": {
        "select": "There is no item",
        "filterNoMatch": "No items match the search"
      }
    },
    "noDataText": {
      "select": "There is no item"
    },
    "action": {
      "send": "Send",
      "save": "Save",
      "edit": "Edit",
      "delete": "Delete",
      "search": "Search",
      "close": "Close",
      "cancel": "Cancel",
      "apply": "Apply",
      "continue": "Continue",
      "confirm": "Confirm",
      "copyToClipboard": "Copy to clipboard"
    },
    "dialog": {
      "confirmation": "CONFIRMATION",
      "information": "INFORMATION",
      "supportInfo": "If you need support you can send email to {email}"
    },
    "notification": {
      "create": {
        "success": "Created successfully",
        "error": "Problems occurred creating the item"
      },
      "update": {
        "success": "Updated successfully",
        "error": "Problems occurred updating the item"
      },
      "delete": {
        "success": "Deleted successfully",
        "error": "Problems occurred deleting the item"
      },
      "read": {
        "error": "Problems occurred loading some data"
      },
      "send": {
        "success": "Successful operation",
        "error": "Problems occurred performing the action"
      }
    },
    "statusOptions": {
      "confirmed": "Confirmed",
      "pending": "Awaiting confirmation",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_cofirmation": "Awaiting confirmation",
      "cancelled": "Canceled",
      "canceled": "Canceled",
      "new": "New",
      "accepted": "Accepted"
    }
  },
  "global": {
    "welcome": {
      "title": "Welcome to the new<br>Victoria B2B!",
      "message": "If this is your first time accessing, you must register to gain access.",
      "contact": "*For any questions, we are at your disposal at <a href=\"mailto:{email}\">{email}</a>"
    },
    "selectLocale": "Select a language",
    "menuLeft": {
      "myOrders": "My orders",
      "selections": "Selections",
      "favorites": "Favorites",
      "communicationMaterial": "Communication material",
      "ordersAndDocuments": "Orders and documents",
      "clients": "Clients"
    },
    "menuTop": {
      "myData": "My data",
      "changeAgent": "Change agent",
      "goToCMS": "Go to CMS",
      "logout": "Logout"
    }
  },
  "auth": {
    "username": "Username / email",
    "password": "Password",
    "repeatPassword": "Repeat password",
    "login": "Login",
    "register": "Register",
    "email": "Write your registration email here",
    "forgotPassword": "Forgot password?",
    "passwordMatch": "Passwords must match",
    "currentPassword": "Write your current password here",
    "newPassword": "Write your new password here",
    "repeatNewPassword": "Repeat your new password here",
    "welcome": "Welcome",
    "selectAgent": "Choose the profile you want to view",
    "changePassword": "Change Password",
    "registerMessage": "We are reviewing your request, you will receive an email confirming the creation of your new account.",
    "forgotPasswordMessage": "A link has been sent to {email}, with information to reset the password.",
    "invalidLink": "The link is not valid or has expired",
    "approveUser": "Do you want to approve the user?",
    "yourSessionIsExpired": "Your session has expired",
    "pleaseReInsertPassword": "Please re-insert your password",
    "ifNotCurrentUserYouCan": "If {user} is not your user, you can ",
    "changeUser": "change user",
    "errors": {
      "register": "To register, please contact us {email} (code: {code})",
      "login": "Problems occurred with authentication on the server",
      "invalidLink": "The link is not valid or has expired (code: {code})",
      "codeInformation": "Problems occurred with authentication on the server (code: {code})"
    },
    "success": {
      "approveUser": "User approved successfully",
      "passwordUpdated": "Password updated successfully"
    }
  },
  "user": {
    "info": {
      "myUser": "My user",
      "nif": "Tax id",
      "nifIncludeLetters": "",
      "name": "Name",
      "customerName": "Customer name",
      "phone": "Phone",
      "cifNif": "Tax id",
      "commercialReason": "Trade name",
      "contactPerson": "Contact person",
      "email": "Email | Emails",
      "clientGroup": "Customer group",
      "template": "Tax return group",
      "subagent": "Subagent",
      "agent": "Agent",
      "userCode": "User code",
      "clientCode": "Customer code",
      "helpText": "If you need to modify any of your data, please send an email to {email}"
    },
    "placeHolder": {
      "customer": {
        "name": "Write here the name of the new client",
        "phone": "Enter the new customer's phone here",
        "businessName": "Write here the business name",
        "commercialReason": "Write here the trade name",
        "contactPerson": "Write the name of the contact person",
        "email": "Write the email",
        "group": "Select a customer group",
        "template": "Select a tax return group",
        "subagent": "Select a subagent"
      }
    },
    "unsubscribe": "Unsubscribe",
    "dialog": {
      "unsubscribe": "Are you sure to request to unsubscribe?"
    }
  },
  "cms": {
    "cmsEditContent": "Edit content",
    "newHome": "Create new Home",
    "setName": "Name",
    "writeName": "Write the name for the new Home",
    "selectedBackground": "Selected background",
    "loadedOptions": "Loaded options",
    "importContent": "Import content",
    "acceptedImages": "Accepted formats JPG, PNG, MP4 and GIF.\n Maximum recommended size 100MB.\n Recommended dimensions 1760 x 574px.",
    "fileOverLimit": "The file exceeds the maximum size",
    "fileInHome": "The file cannot be deleted as it is being used in {homes}.",
    "confirmDelete": "Are you sure you want to delete the banner content?",
    "addProducts": "Add products",
    "selectProducts": "Select products",
    "selectedProducts": "Selected products",
    "searchByCode": "Search by code",
    "name": "Name",
    "homes": "Homes",
    "products": "Products",
    "selectBannerMedia": "Select image / video for the banner",
    "admin": "CMS Admin",
    "menu": {
      "b2b": "Go to B2B",
      "text": "Banner text",
      "content": "Banner content",
      "products": "Products",
      "preview": "Preview",
      "priceGroups": "Price groups"
    },
    "tabs": {
      "notice": "Notice",
      "extraInfo": "Extra info",
      "copy": "Copy"
    },
    "fields": {
      "phone": "Phone",
      "description": "Description",
      "title": "Title",
      "showInfo": "Show information"
    }
  },
  "product": {
    "sizes": "Size | Sizes",
    "from": "From",
    "to": "to",
    "sizeCurve": "Size curve",
    "info": "Info",
    "composition": "Composition",
    "pvp": "P.V.P.",
    "pc": "P.C.",
    "legendGreen": "+ 20 pairs in stock",
    "legendYellow": "- 20 pairs in stock",
    "legendRed": "No stock",
    "legendBlack": "Unavailable",
    "units": "units",
    "season": "Season",
    "sku": "SKU",
    "prices": "Price | Prices",
    "quantities": "Quantity | Quantities",
    "serie": "Serie"
  },
  "address": {
    "name": "Name",
    "street": "Address | Addresses",
    "city": "City",
    "city2": "City",
    "region": "Region",
    "zip": "Postal Code",
    "country": "Country",
    "addTitle": "Add new address",
    "editTitle": "Edit address",
    "areAddressesEquals": "Are the billing and shipping addresses the same?",
    "addressName": "Address name",
    "placeHolder": {
      "default": {
        "select": "Select a shipping address"
      },
      "customer": {
        "nif": "Type here the Tax id of the {item}",
        "name": "Type here a name for your new address",
        "street": "Type here the address of the {item}",
        "city": "Type here the population of the {item}",
        "region": "Type here the province of the {item}",
        "zip": "Enter the zip code of the {item} here",
        "country": "Select a country"
      },
      "shipping": {
        "name": "Type here the name",
        "street": "Type here the shipping address",
        "city": "Type here the population",
        "region": "Type here the province",
        "zip": "Type here the zip code"
      }
    }
  },
  "order": {
    "billingAddress": "Billing address",
    "client": "Customer",
    "contactData": "Contact information",
    "returnToOrder": "Back to order",
    "origin": "Origin",
    "quantityOrdered": "Q. ordered",
    "quantityPending": "Q. pending",
    "quantitySent": "Q. sent",
    "complete": "Complete | Complete",
    "pending": "Pending | Pending",
    "detailTitle": "Order information",
    "reference": "Order reference",
    "articles": "Article | Articles",
    "pendingQuantities": "Outstanding quantity | Outstanding quantities",
    "repeat": "Repeat order",
    "pairTotal": "Total quantity",
    "requestedDate": "Requested date",
    "payment": {
      "method": "Payment method",
      "form": "Payment form",
      "bank": "Bank | Banks",
      "bankName": "Bank name",
      "iban": "IBAN",
      "terms": "Payment terms",
      "observations": "Observations",
      "clientObservations": "Client observations",
      "allowsOrders": "Allows orders",
      "bankCode": "Bank code",
      "bankInfo": "If you need to modify or add a bank, please send an email to {email}",
      "placeHolder": {
        "form": "Select a payment form",
        "bankName": "Write here the name of the bank",
        "iban": "Write here the IBAN",
        "terms": "Select some payment terms"
      }
    },
    "shipping": {
      "address": "Shipping address | Shipping addresses",
      "addressInfo": "If you need to add or edit an address send us an email to {email}",
      "editAddress": "Edit shipping address",
      "addAddress": "Add new shipping address"
    },
    "price": {
      "shippingCost": "Shipping cost",
      "transportCost": "Transport cost {price}",
      "freeShippingFrom": "Free shipping from {price}",
      "amount": "Amount",
      "grossAmount": "Gross amount",
      "taxes": "Taxes",
      "type": "Type",
      "base": "Base",
      "amountTax": "Fee",
      "discounts": "Discounts",
      "surcharges": "Surcharges",
      "totalSum": "Total amount",
      "subtotal": "Subtotal",
      "subtotalWithDiscounts": "Subtotal with discounts",
      "taxAmount": "Tax amount",
      "paymentTermsDiscount": "Payment terms discount",
      "customerDiscount": "Customer discount",
      "shipping": "Shipping",
      "surchargeAmount": "Surcharge amount",
      "freeShippingFromWarning": "To qualify for free shipping, the order amount must be greater than {amount} {currency}."
    },
    "customer": {
      "label": "customer",
      "new": "new customer",
      "existing": "existing customer",
      "select": "Select the client",
      "businessName": "Business name",
      "create": {
        "success": "Your new customer has been successfully saved. | Your new customer has been successfully saved. You can now complete your order.",
        "error": "Problems occurred saving the client."
      }
    },
    "notifications": {
      "success": "Your order has been placed successfully. You will shortly receive an email with all the information."
    }
  },
  "cart": {
    "title": "Cart",
    "addToCart": "Add to cart",
    "doOrder": "Order",
    "totalProduct": "Total current product",
    "totalProducts": "Total products",
    "quantity": "units",
    "emptyMessage": "Your cart is empty. Add products to make an order.",
    "goToCart": "Go to cart",
    "added": {
      "addedProducts": "The products have been added to the cart",
      "addedQuantity": "1 unit have been added to the cart. | {count} units have been added to the cart.",
      "addedQuantities": "The units have been added to the cart",
      "noAddedQuantityNoAvailable": "1 model could not be added because it is not available at the moment. | {count} models could not be added because they are not available at this time."
    },
    "order": {
      "completeTitle": "Complete order data",
      "requestedIssueDate": "Requested issue date",
      "make": "Make an order",
      "yourOrderReference": "Your order reference"
    }
  },
  "filter": {
    "filters": "Filters",
    "search": "Search",
    "searchIn": "Search in",
    "nameASC": "Name from A to Z",
    "nameDESC": "Name from Z to A",
    "priceASC": "Price from lower to higher",
    "priceDESC": "Price from higher to lower",
    "skuASC": "Reference from lower to higher",
    "skuDESC": "Reference from higher to lower",
    "type": "Type",
    "sole": "Sole",
    "section": "Section",
    "size": "Size",
    "availability": "Availability",
    "availableStock": "Available stock",
    "color": "Color",
    "category": "Category",
    "availabilityQuote": "Show only products with stock",
    "boy": "Boy",
    "girl": "Girl",
    "children": "Children",
    "searchMinLength": "The search must contain at least {count} characters",
    "withStock": "Availability"
  },
  "selections": {
    "title": "Selections",
    "advice": "To create a new selection, choose the color model with the heart and give your selection a name. Later, you'll be able to send it via email and/or turn it into an order.",
    "btnNew": "New Selection",
    "addNameTitle": "Name new selection",
    "renameTitle": "Rename “{name}”.",
    "placeHolderName": "Selection name",
    "btnMoveToCart": "Move Selection to Cart",
    "returnToItem": "Return to selection",
    "dialog": {
      "deleteMessage": "Are you sure to delete this Selection? | Are you sure to remove these {count} selections?",
      "deleteProducts": "Are you sure about removing this product from the selection? | Are you sure to remove these {count} products-colors from the selection?",
      "moveToCartMessage": "Are you sure to move this selection to the cart?",
      "cartAlreadyExists": "Attention, the cart already contains products.",
      "saveTo": "Save to Selections",
      "sendByEmail": "Send selection by email"
    },
    "notification": {
      "create": {
        "success": "Selection created successfully",
        "error": "Problems occurred creating the selection"
      },
      "addProduct": {
        "success": "Product has been saved to “{name}” successfully",
        "error": "Problems occurred saving product selection"
      },
      "removeProduct": {
        "success": "Successfully removed from selection",
        "error": "Problems occurred deselecting the product"
      },
      "emailSent": {
        "success": "Email sent successfully",
        "error": "Problems occurred sending the email"
      }
    }
  },
  "rules": {
    "required": "Required",
    "nameFormat": "Invalid name",
    "emailFormat": "Invalid email",
    "phoneNumber": "Invalid phone number",
    "password": "The password must contain at least 8 characters, one uppercase letter, one lowercase letter and one of these symbols: .-_ !?",
    "passwordConfirmation": "The password confirmation does not match",
    "number": "Invalid number",
    "web": "Invalid website",
    "decimalNumber": "Invalid decimal number",
    "minCharacter": "Min 8 characters",
    "emailTaken": "The email has already been taken",
    "passwordNewDifferent": "The new password must be different from the current one",
    "usernameTaken": "Username has already been taken",
    "username": "Invalid username",
    "zipCode": "Invalid zip code",
    "nif": "Invalid Tax id",
    "iban": "Invalid Iban",
    "maxCharacter": "Max {max} characters",
    "customerOrderId": "The reference code can only contain uppercase letters (A-Z and Ñ), numbers (0-9), spaces and the following special characters: , . _ - / ( )."
  },
  "table": {
    "of": "of",
    "rowCount": "Rows per page"
  },
  "documents": {
    "order": "Order | Orders",
    "invoice": "Invoice | Invoices",
    "deliveryNote": "Delivery note | Delivery notes",
    "refund": "Refund | Refunds",
    "ordersAndDocuments": "Orders and documents",
    "zipCode": "Invalid zip code",
    "nif": "Invalid Tax id",
    "iban": "Invalid Iban",
    "pdf": "PDF",
    "availablePDF": "Available PDF",
    "completePDF": "Complete PDF",
    "pendingPDF": "PDF pending pairs",
    "requestPDF": "Request PDF",
    "requestedPDF": "PDF requested",
    "orderActions": "Acciones pedido",
    "package": "Package | Packages",
    "notification": {
      "request": {
        "success": "PDF request sent successfully. This operation will take a few minutes.",
        "error": "Problems occurred sending the PDF request"
      },
      "download": {
        "success": "PDF downloaded successfully",
        "error": "Problems occurred downloading the PDF"
      }
    }
  },
  "notFound": {
    "title": "Oops!",
    "subtitle": "Something went wrong",
    "message": "The url you are trying to access does not exist or has been deleted.",
    "back": "Back to home"
  },
  "$vuetify": {
    "input": {
      "clear": ""
    }
  },
  "icon": {
    "title": {
      "trash": "Delete",
      "resizeToggled": "Fold",
      "resize": "Unfold",
      "clearQuantity": "Clear quantities",
      "copyChecked": "Copy",
      "copy": "Copy",
      "paste": "Paste",
      "search": "Search",
      "cart": "Cart",
      "cartMarked": "Cart",
      "heart": "Add to favorites",
      "info": "Information"
    }
  }
}
