{
  "common": {
    "code": "Codice",
    "title": "Titolo",
    "description": "Descrizione",
    "yes": "Sì",
    "no": "No",
    "ok": "Ok",
    "back": "Indietro",
    "percent": "%",
    "comment": "Commento",
    "date": "Data",
    "expiration": "Scadenza",
    "data": "Dato|Dati",
    "status": "Stato|Stati",
    "information": "Informazione",
    "see": "Vedere",
    "colors": "Colore|Colori",
    "total": "Totale",
    "erp": "ERP",
    "b2b": "B2B",
    "and": "e",
    "form": {
      "placeHolder": {
        "date": "Selezioni una data",
        "comment": "Scrivi qui il tuo commento",
        "email": "Scrivi qui le email separate da virgole",
        "orderName": "Scrivi qui il nome dell'ordine"
      },
      "noDataText": {
        "select": "Non c'è alcun elemento",
        "filterNoMatch": "Nessun elemento corrisponde ai criteri di ricerca"
      }
    },
    "noDataText": {
      "select": "Selezione"
    },
    "action": {
      "send": "Inviare",
      "save": "Salvare",
      "edit": "Modificare",
      "delete": "Cancellare",
      "search": "Cercare",
      "close": "Chiudere",
      "cancel": "Annullare",
      "apply": "Applicare",
      "continue": "Continuare",
      "confirm": "Confermare",
      "copyToClipboard": "Copia negli appunti"
    },
    "dialog": {
      "confirmation": "Conferma",
      "information": "Informazione",
      "supportInfo": "Se hai bisogno di supporto puoi inviare una e-mail a {email}"
    },
    "notification": {
      "create": {
        "success": "Creato correttamente",
        "error": "Si sono verificati dei problemi creando l'elemento"
      },
      "update": {
        "success": "Aggiornato correttamente",
        "error": "Si sono verificati dei problemi aggiornando l'elemento"
      },
      "delete": {
        "success": "Cancellato correttamente",
        "error": "Si sono verificati dei problemi cancellando l'elemento"
      },
      "read": {
        "error": "Si sono verificati dei problemi caricando alcuni dati"
      },
      "send": {
        "success": "Operazione eseguita con successo",
        "error": "Si sono verificati dei problemi realizzando l'azione"
      }
    },
    "statusOptions": {
      "confirmed": "Confermato",
      "pending": "In attesa di conferma",
      "awaiting_confirmation": "In attesa di conferma",
      "awaiting_cofirmation": "In attesa di conferma",
      "cancelled": "Cancellato",
      "canceled": "Cancellato",
      "new": "Nuovo",
      "accepted": "Accettato"
    }
  },
  "global": {
    "welcome": {
      "title": "Benvenuto al nuovo<br>B2B di Victoria!",
      "message": "Se è la prima volta che accedi, devi registrarti per poter accedere.",
      "contact": "*Per qualsiasi domanda, siamo a tua disposizione a <a href=\"mailto:{email}\">{email}</a>"
    },
    "selectLocale": "Seleziona una lingua",
    "menuLeft": {
      "myOrders": "I miei ordini",
      "selections": "Selezioni",
      "favorites": "Preferiti",
      "communicationMaterial": "Materiale comunicazione",
      "ordersAndDocuments": "Ordini e documenti",
      "clients": "Clienti"
    },
    "menuTop": {
      "myData": "I miei dati",
      "changeAgent": "Cambio di agente",
      "goToCMS": "Vai al CMS",
      "logout": "Chiudere sessione"
    }
  },
  "auth": {
    "username": "Nome utente/e-mail",
    "password": "Password",
    "repeatPassword": "Ripetere password",
    "login": "Iniziare sessione",
    "register": "Registro",
    "email": "Scrivi qui la tua e-mail di registrazione",
    "forgotPassword": "Hai dimenticato la password?",
    "passwordMatch": "La password deve coincidere",
    "newPassword": "Scrivi qui la nuova password",
    "repeatNewPassword": "Ripeti la nuova password",
    "welcome": "Ti diamo il benvenuto",
    "selectAgent": "Scegli il profilo che desiderate visualizzare",
    "changePassword": "Cambiare la pasword",
    "currentPassword": "Password attuale",
    "registerMessage": "Stiamo verificando la tua richiesta, riceverai una e-mail che conferma la creazione del tuo nuovo account.",
    "forgotPasswordMessage": "È stato inviato un collegamento a {email}, con le informazioni per reimpostare la password.",
    "invalidLink": "Il link non è valido o è scaduto",
    "approveUser": "Sei sicuro di approvare l'utente?",
    "yourSessionIsExpired": "La tua sessione è scaduta",
    "pleaseReInsertPassword": "Inserisci nuovamente la tua password",
    "ifNotCurrentUserYouCan": "Se {user} non è il tuo utente, puoi ",
    "changeUser": "cambiare utente",
    "errors": {
      "register": "Per attivare il account, contattaci a {email} (codice: {code})",
      "login": "Si sono verificati problemi con l'autenticazione nel server",
      "invalidLink": "Il link non è valido o è scaduto (codice: {code})",
      "codeInformation": "Si sono verificati problemi con l'autenticazione nel server (codice: {code})"
    },
    "success": {
      "approveUser": "Utente approvato con successo",
      "passwordUpdated": "Password aggiornata con successo"
    }
  },
  "user": {
    "info": {
      "myUser": "Il mio nome utente",
      "nif": "Partita Iva",
      "nifIncludeLetters": "(lettere incluse)",
      "name": "Nome",
      "customerName": "Nome cliente",
      "phone": "Telefono",
      "cifNif": "Partita Iva/CIF",
      "commercialReason": "Nome commerciale",
      "contactPerson": "Persona di contatto",
      "email": "Posta elettronica | Posta elettronica",
      "clientGroup": "Gruppo cliente",
      "template": "Modello di dichiarazione dei redditi",
      "subagent": "Subagente",
      "agent": "Agente",
      "userCode": "Codice utente",
      "clientCode": "Codice cliente",
      "helpText": "Se hai bisogno di modificare qualsiasi dei tuoi dati, invia per favore una e-mail a {email}"
    },
    "placeHolder": {
      "customer": {
        "name": "Scrivi qui i dati del nuovo cliente",
        "phone": "Scrivi qui il telefono del nuovo cliente",
        "businessName": "Scrivi qui il nome dell'azienda",
        "commercialReason": "Scrivi qui il nome commerciale",
        "contactPerson": "Scrivi il nome della persona di contatto",
        "email": "Scrivi la e-mail",
        "group": "Selezioni un gruppo cliente",
        "template": "Selezione un modello di dichiarazione dei redditi",
        "subagent": "Selezioni un subagente"
      }
    },
    "unsubscribe": "Cancellare l'iscrizione",
    "dialog": {
      "unsubscribe": "Sei sicuro di richiedere cancellare l'iscrizione?"
    }
  },
  "cms": {
    "cmsEditContent": "Modificare il contenuto",
    "newHome": "Creare nuova home",
    "setName": "Nominare",
    "writeName": "Scrivi qui il nome della nuova home",
    "selectedBackground": "Sfondo selezionato",
    "loadedOptions": "Opzioni caricate",
    "importContent": "Importare contenuto",
    "acceptedImages": "Formati accettati  JPG, PNG, MP4 e GIF.\n            Dimensione massima raccomandata 100MB.\n            Misure raccomandate 1760 x 574px.",
    "fileOverLimit": "Il file selezionato eccede la dimensione massima",
    "fileInHome": "Non si può cancellare il file perché viene utilizzato da {homes}",
    "confirmDelete": "Sicuro di cancellare il contenuto del banner?",
    "addProducts": "Aggiungi prodotti",
    "selectProducts": "Seleziona prodotti",
    "selectedProducts": "Prodotti selezionati",
    "searchByCode": "Cerca per codice",
    "name": "Nome",
    "homes": "Home",
    "products": "Prodotti",
    "selectBannerMedia": "Seleziona immagine / video per il banner",
    "admin": "Amministratore CMS",
    "menu": {
      "text": "Testo del banner",
      "content": "Contenuto del banner",
      "products": "Prodotti",
      "preview": "Previsualizzazione",
      "priceGroups": "Fascia di prezzo",
      "b2b": "Vai a B2B"
    },
    "tabs": {
      "notice": "Avviso",
      "extraInfo": "Info extra",
      "copy": "Copy"
    },
    "fields": {
      "phone": "Telefono",
      "description": "Descrizione",
      "title": "Titolo",
      "showInfo": "Visualizzare informazioni"
    }
  },
  "product": {
    "sizes": "Taglia|Taglie",
    "from": "Da",
    "to": "Fino a",
    "sizeCurve": "Curva di taglie",
    "info": "Info",
    "composition": "Composizione",
    "pvp": "PVC",
    "pc": "PC",
    "legendGreen": "+ 20 paia in stock",
    "legendYellow": "- 20 paia in stock",
    "legendRed": "Senza stock",
    "legendBlack": "Non disponibile",
    "units": "Unità",
    "season": "Stagione",
    "sku": "SKU",
    "prices": "Prezzo|Prezzi",
    "quantities": "Quantità|Quantità",
    "serie": "Serie"
  },
  "address": {
    "name": "Nome",
    "street": "Indirizzo|Indirizzi",
    "city": "Località",
    "city2": "Città",
    "region": "Regione",
    "zip": "Codice postale",
    "country": "Paese",
    "addTitle": "Aggiungere nuovo indirizzo",
    "editTitle": "Modificare indirizzo",
    "areAddressesEquals": "L'indirizzo di fatturazione e consegna sono gli stessi?",
    "addressName": "Nome dell'indirizzo",
    "placeHolder": {
      "default": {
        "select": "Selezioni un indirizzo di consegna"
      },
      "customer": {
        "nif": "Scrivi qui il Partita Iva del {item}",
        "name": "Scrivi qui un nome per il suo nuovo indirizzo",
        "street": "Scrivi qui l'indirizzo del  {item}",
        "city": "Scrivi qui la località del  {item}",
        "region": "Scrivi qui la regione del {item} ",
        "zip": "Scrivi qui il codice postale del {item}",
        "country": "Scegli un paese"
      },
      "shipping": {
        "name": "Scrivi qui il nome",
        "street": "Scrivi qui l'indirizzo di consegna",
        "city": "Scrivi qui la località",
        "region": "Scrivi qui la regione",
        "zip": "Scrivi qui il codice postale"
      }
    }
  },
  "order": {
    "billingAddress": "Indirizzo di fatturazione",
    "client": "Cliente",
    "contactData": "Dati di contatto",
    "returnToOrder": "Ritornare all'ordine",
    "origin": "Origine",
    "quantityOrdered": "Quantità ordinata",
    "quantityPending": "Quantità in sospeso",
    "quantitySent": "Quantità spedita",
    "complete": "Completo|Completi",
    "pending": "In sospeso",
    "detailTitle": "Informazioni sull'ordine",
    "reference": "Riferimento dell'ordine",
    "articles": "Articolo|Articoli",
    "pendingQuantities": "Quantità in sospeso|Quantità in sospeso",
    "repeat": "Ripetere ordine",
    "pairTotal": "quantità totale",
    "requestedDate": "Data richiesta",
    "payment": {
      "method": "Modalità di pagamento",
      "form": "Metodo di pagamento",
      "bank": "Banca| Banche",
      "bankName": "Nome della banca",
      "iban": "IBAN",
      "terms": "Termini di pagamento",
      "observations": "Osservazioni",
      "clientObservations": "Osservazioni del cliente",
      "allowsOrders": "Permette ordini",
      "bankCode": "Codice banca",
      "bankInfo": "Se dovete modificare o aggiungere una banca La prego d'inviare una email a {email}",
      "placeHolder": {
        "form": "Seleziona un metodo di pagamento",
        "bankName": "Scrivi qui il nome della banca",
        "iban": "Scrivi qui il IBAN",
        "terms": "Selezioni termini di pagamento"
      }
    },
    "shipping": {
      "address": "Indirizzo di consegna|Indirizzi di consegna",
      "addressInfo": "Se dovete aggiungere o modificare un indirizzo di consegna La prego d'inviare una email a {email}",
      "editAddress": "Modificare indirizzo di consegna",
      "addAddress": "Aggiungere nuovo indirizzo di consegna"
    },
    "price": {
      "shippingCost": "Spese di spedizione",
      "transportCost": "Costo trasporto {price}",
      "freeShippingFrom": "Spedizione gratuita da  {price}",
      "amount": "Importo",
      "grossAmount": "Importo lordo",
      "taxes": "Tasse",
      "type": "Tipo",
      "base": "Base",
      "amountTax": "Quota",
      "discounts": "Sconti",
      "surcharges": "Supplemento",
      "totalSum": "Somma totale",
      "subtotal": "Subtotale",
      "subtotalWithDiscounts": "Subtotale con sconti",
      "taxAmount": "Importo tasse",
      "paymentTermsDiscount": "Sconto termini di pagamento",
      "customerDiscount": "Sconto cliente",
      "shipping": "Spedizione",
      "surchargeAmount": "Importo supplemento",
      "freeShippingFromWarning": "Per usufruire della spedizione gratuita, l'importo dell'ordine deve essere superiore a {amount} {currency}."
    },
    "customer": {
      "label": "Cliente",
      "new": "Nuovo cliente",
      "existing": "Cliente esistente",
      "select": "Selezioni il cliente",
      "businessName": "Ragione sociale",
      "create": {
        "success": "Il nuovo cliente è stato salvato correttamente. | Il nuovo cliente è stato salvato correttamente. Potete completare l'ordine",
        "error": "Si è verificato un problema salvando il cliente"
      }
    },
    "notifications": {
      "success": "Il Suo ordine è stato completato correttamente. A breve riceverà una email con tutta l'informazione."
    }
  },
  "cart": {
    "title": "Carrello",
    "addToCart": "Aggiungi al carrello",
    "doOrder": "Invia ordine",
    "totalProduct": "Totale prodotti attuale",
    "totalProducts": "Totale prodotti",
    "quantity": "pz",
    "emptyMessage": "Il tuo carrello è vuoto, aggiungi dei prodotti per effetuare il seguente ordine",
    "goToCart": "Vai al carrello",
    "added": {
      "addedProducts": "I prodotti sono stati aggiunti al carrello",
      "addedQuantities": "Le unità sono state aggiunte al carrello.",
      "addedQuantity": "È stato aggiunto un'unità nel carrello| È stato aggiunto {count} unità nel carrello ",
      "noAddedQuantityNoAvailable": "Un modello non è stato aggiunto perché non è disponibile in questo momento. {count} modelli non sono stati aggiunti perché non sono disponibili in questo momento"
    },
    "order": {
      "completeTitle": "Completare dati ordini",
      "requestedIssueDate": "Data di spedizione richiesta",
      "make": "Ordinare",
      "yourOrderReference": "Riferimento del tuo ordine"
    }
  },
  "filter": {
    "filters": "Filtri",
    "search": "Ricerca",
    "searchIn": "Cercare in",
    "nameASC": "Nome della A alla Z",
    "nameDESC": "Nome della Z alla A",
    "priceASC": "Prezzo dal maggiore al minore",
    "priceDESC": "Prezzo dal minore al maggiore",
    "skuASC": "Riferimento dal minore al maggiore",
    "skuDESC": "Riferimento dal maggiore al minore",
    "type": "Tipo",
    "sole": "Suola",
    "section": "Sezione",
    "size": "Taglia",
    "availability": "Disponibilità",
    "availableStock": "Stock disponibile",
    "color": "Colore",
    "category": "Categoria",
    "availabilityQuote": "Mostrare solo prodotti con stock",
    "boy": "Bambino",
    "girl": "Bambina",
    "children": "Bambini",
    "searchMinLength": "La ricerca deve contenere almeno {min} caratteri",
    "withStock": "Disponibile"
  },
  "selections": {
    "title": "Selezioni",
    "advice": "Per creare una nuova selezione, scegli il modello colore con il cuore e dai un nome alla tua selezione. Successivamente potrai inviarla via email e/o trasformarla in un ordine.",
    "btnNew": "Nuova selezione",
    "addNameTitle": "Denominare nuova selezione",
    "renameTitle": "Rinominare“{name}”",
    "placeHolderName": "Nome della selezione",
    "btnMoveToCart": "Spostare la selezione al carrello",
    "returnToItem": "Tornare alla selezione",
    "dialog": {
      "deleteMessage": "Sei sicuro di cancellare questa selezione?|Sei sicuro di cancellare queste {count} selezione?",
      "deleteProducts": "Sei sicuro di cancellare questo prodotto della selezione? | Sei sicuro di cancellare questi {count} prodotti- colori della selezione?",
      "moveToCartMessage": "Sei sicuro di spostare questa selezione al carrello?",
      "cartAlreadyExists": "Attenzione, il carrello contiene già prodotti.",
      "saveTo": "Salvare in selezioni",
      "sendByEmail": "Inviare selezione per e-mail"
    },
    "notification": {
      "create": {
        "success": "Selezione creata correttamente",
        "error": "Si sono verificati problemi con la creazione della selezione"
      },
      "addProduct": {
        "success": "Il prodotto è stato salvato in “{name}” correttamente",
        "error": "Si sono verificati problemi salvando la selezione del prodotto"
      },
      "removeProduct": {
        "success": "Cancellato correttamente della selezione",
        "error": "Si sono verificati problemi deselezionando il prodotto"
      },
      "emailSent": {
        "success": "Email inviata correttamente",
        "error": "Si sono verificati problemi inviando l'email"
      }
    }
  },
  "rules": {
    "required": "Richiesto",
    "nameFormat": "Nome non valido",
    "emailFormat": "E-mail non valido",
    "phoneNumber": "Numero di telefono non valido",
    "password": "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola e uno di questi simboli: .-_ !?",
    "passwordConfirmation": "La conferma della password non coincide",
    "number": "Numero non valido",
    "web": "Sito web non valido",
    "decimalNumber": "Numero decimale non valido",
    "minCharacter": "minimo 8 caratteri",
    "emailTaken": "Indirizzo e-mail già utilizzato",
    "passwordNewDifferent": "La nuova password deve essere diversa dalle attuale",
    "usernameTaken": "Nome utente già in uso",
    "username": "Nome utente non valido",
    "zipCode": "Codice postale non valido",
    "nif": "Partita Iva non valido",
    "iban": "IBAN non valido",
    "maxCharacter": "Massimo {max} caratteri",
    "customerOrderId": "Il codice di riferimento può contenere solo lettere maiuscole (A-Z e Ñ), numeri (0-9), spazi e i seguenti caratteri speciali: , . _ - / ( )."
  },
  "table": {
    "of": "",
    "rowCount": "Righe per pagina"
  },
  "documents": {
    "order": "Ordine|Ordini",
    "invoice": "Fattura|Fatture",
    "deliveryNote": "Bolla di consegna| Bolle di consegna",
    "refund": "Nota di credito| Note di credito",
    "ordersAndDocuments": "Ordini e documenti",
    "zipCode": "Codice postale non valido",
    "nif": "Partita Iva non valido",
    "iban": "IBAN no valido",
    "pdf": "PDF",
    "availablePDF": "PDF disponibile",
    "completePDF": "PDF completo",
    "pendingPDF": "PDF coppie in sospeso",
    "requestPDF": "Sollecitare PDF",
    "requestedPDF": "PDF richiesto",
    "orderActions": "Azioni ordini Acciones pedido",
    "package": "Collo|Colli",
    "notification": {
      "request": {
        "success": "La richiesta di PDF è stata inviata correttamente. Questa operazione richiederà alcuni minuti.",
        "error": "Si sono verificati problemi con l'invio della richiesta di PDF"
      },
      "download": {
        "success": "PDF scaricato correttamente",
        "error": "Si sono verificati problemi con il download del PDF"
      }
    }
  },
  "notFound": {
    "title": "Oops!",
    "subtitle": "Qualcosa è andato storto",
    "message": "L'URL a cui stai cercando di accedere non esiste o è stata eliminata.",
    "back": "Torna alla home"
  },
  "$vuetify": {
    "input": {
      "clear": ""
    }
  },
  "icon": {
    "title": {
      "trash": "Elimina",
      "resizeToggled": "Piegare",
      "resize": "Schiudere",
      "clearQuantity": "Cancellare quantità",
      "copyChecked": "Copia",
      "copy": "Copia",
      "paste": "Incolla",
      "search": "Cerca",
      "cart": "Carrello",
      "cartMarked": "Carrello",
      "heart": "Aggiungi ai preferiti",
      "info": "Informazioni"
    }
  }
}
